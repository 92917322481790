import {DateTime} from 'luxon';
import {toast} from 'vue3-toastify';

type Product = {
  id: number,
  is_favorite: boolean,
};

export const useFavoritesStore = defineStore('favorites', () => {
  const vuexState = useVuexPersistedState();
  const productIds = useCookie<number[]>('favorites.products', {
    default: () => vuexState.value?.favorites?.favorite_products?.map(product => product.id) ?? [],
    expires: DateTime.now().plus({years: 1}).toJSDate(),
  });

  const user = useSofieUserWhenAuthenticated();
  const {client: apollo} = useApolloClient();

  return {
    productIds,

    isFavorite(product: Product) {
      return user.value
        ? product.is_favorite
        : productIds.value.find(id => id === product.id) !== undefined;
    },

    async addFavorite(product: Product) {
      const exists = productIds.value.findIndex(id => id === product.id);

      if (exists === -1) {
        productIds.value.push(product.id);
      }
    },

    async removeFavorite(product: Product) {
      await this.removeFavorites([product.id]);
    },

    async removeFavorites(ids: Array<number>) {
      if (user.value) {
        try {
          await apollo.mutate({
            mutation: DeleteFavoriteProductsDocument,
            variables: {
              products: ids,
            },
          });
        } catch (e) {
          console.error(e);
          toast.error('Er is iets mis gegaan tijdens het verwijderen van het product uit uw favorieten. Probeer het later nog eens.');
          return;
        }
      } else {
        productIds.value = productIds.value.filter(id => !ids.includes(id));
      }

      toast.success(
        ids.length === 1
          ? 'Materiaal verwijderd van je favorietenlijst.'
          : `${ids.length} materialen verwijderd van je favorietenlijst.`,
      );
    },
  };
});
