/**
 * This function serves as backwards compatibility to preserve the Vuex
 * state and allow for a smooth transition to the new Pinia stores. This
 * function can likely be removed a few months after the migration to
 * Nuxt 3 is complete.
 */
export function useVuexPersistedState() {
  return useLocalStorage<{
    dashboard?: {
      selectedOrganisation: number | null,
    },
    favorites: {
      favorite_products: Array<Product>,
    },
  } | undefined>('vuex', () => undefined, {
    writeDefaults: false,
  });
}
